<template>
  <v-container fluid :class="selected.length > 0 ? 'pb-16' : ''">
    <v-row v-if="!isOrdering">
      <v-col cols="12" md="6">
        <SearchBar
          ref="searchBar"
          @search="onSearch"
          searchLabel="Search by label name..."
        />
      </v-col>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="msaBlue"
              class="white--text"
              v-bind="attrs"
              v-on="on"
              @click="goToDetail()"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Label</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <AddLabelGroupDialog @labelGroupAdded="onLabelGroupAdded">
        </AddLabelGroupDialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="resultMessage.length">{{ resultMessage }}</div>
        <v-expansion-panels
          transition="fade-transition"
          v-model="expanded"
          multiple
          active-class="msaBlue white--text"
        >
          <v-expansion-panel
            v-for="(group, index) in groups"
            :key="index"
            class="my-2"
          >
            <v-expansion-panel-header
              hide-actions
              v-slot="{ open }"
              :style="headerStyles(index)"
            >
              <v-row no-gutters align="center">
                <v-col class="shrink" :cols="isMobile ? 1 : false">
                  <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
                  <v-icon v-else>mdi-plus-thick</v-icon>
                </v-col>
                <v-col
                  class="grow pl-4 font-weight-medium"
                  :cols="isMobile ? 11 : false"
                >
                  <span>{{ group.name }}</span>
                  <v-badge
                    inline
                    color="success"
                    :content="group.count || '0'"
                    :value="group.count || '0'"
                  >
                  </v-badge>
                </v-col>
                <v-spacer></v-spacer>
                <v-col align="right">
                  <v-row dense>
                    <v-spacer></v-spacer>
                    <v-col v-if="!isOrdering"
                      ><EditLabelGroupDialog
                        :group="group"
                        :isGroupExpanded="open"
                        @labelGroupUpdated="onLabelGroupUpdated"
                      ></EditLabelGroupDialog
                    ></v-col>
                    <v-col v-if="canBeOrdered" class="shrink" align="right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-blur
                            :text="!open"
                            small
                            v-on="on"
                            @click.native="
                              startOrderGroups($event);
                              group.isOrdering = true;
                            "
                          >
                            <v-icon> mdi-swap-vertical </v-icon>
                          </v-btn>
                        </template>
                        <span>Change Position</span>
                      </v-tooltip>
                    </v-col>
                    <v-col v-if="group.isOrdering">
                      <ChangeOrderButtonGroup
                        :currentIndex="index"
                        :maxIndex="groups.length - 1"
                        @updatePositions="updateGroupPositions()"
                        @onOrder="changeGroupOrder($event)"
                      />
                    </v-col>
                    <v-col class="shrink" v-if="!isOrdering">
                      <DeleteLabelGroupDialog
                        :labelGroup="group"
                        :isGroupExpanded="open"
                        @labelGroupDelete="onLabelGroupDelete(group)"
                      ></DeleteLabelGroupDialog>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <LabelsTable
                :key="labelsTableKey"
                :search="search"
                :selected="selected"
                :groupId="group.id"
                :labelCount="group.count"
                @onSelect="onSelect($event)"
              ></LabelsTable>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <LabelsSelectDialog
      :groups="groups"
      :selectedItems="selected"
      v-if="selected.length > 0 && !isOrdering"
      @onUnselect="handleUnselect($event)"
      @deleteSelected="refreshGroups"
      @moveSelected="refreshGroups"
    >
    </LabelsSelectDialog>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import DeleteLabelGroupDialog from '@/components/Labels/IA/DeleteLabelGroupDialog.vue';
import ChangeOrderButtonGroup from '@/components/ChangeOrderButtonGroup.vue';
import AddLabelGroupDialog from '../../components/Labels/IA/AddLabelGroupDialog.vue';
import EditLabelGroupDialog from '../../components/Labels/IA/EditLabelGroupDialog.vue';
import LabelsTable from '../../components/Labels/IA/LabelsTable.vue';
import LabelsSelectDialog from '../../components/Labels/IA/LabelsSelectDialog.vue';

export default {
  name: 'LabelsPage',
  components: {
    SearchBar,
    DeleteLabelGroupDialog,
    ChangeOrderButtonGroup,
    AddLabelGroupDialog,
    EditLabelGroupDialog,
    LabelsTable,
    LabelsSelectDialog,
  },
  data() {
    return {
      groups: [],
      search: '',
      expanded: [],
      savedPanelState: [],
      selected: [],
      isOrdering: false,
      labelsTableKey: 0,
      isLoading: false,
    };
  },
  watch: {
    search: function () {
      this.getGroups();
    },
  },
  computed: {
    resultMessage() {
      if (!this.isLoading && !this.groups.length && this.search.trim() != '') {
        return 'No labels found matching your search.';
      }
      return '';
    },
    headerStyles() {
      return (index) =>
        this.expanded.findIndex((i) => i == index) == -1
          ? {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            }
          : {};
    },
    canBeOrdered() {
      return !this.isOrdering && !this.search.length;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    onLabelGroupUpdated(data) {
      const index = this.groups.findIndex((g) => g.id == data.id);
      if (index != -1) {
        this.groups[index].name = data.name;
      }
    },
    loadPanelState() {
      const expanded = [];
      this.savedPanelState.forEach((id) => {
        const index = this.groups.findIndex((g) => g.id == id);
        if (index != -1) {
          expanded.push(index);
        }
      });
      this.expanded = expanded;
    },
    savePanelState() {
      this.savedPanelState = this.expanded.map((index) => {
        return this.groups[index].id;
      });
    },
    onSearch(data) {
      this.search = data.trimStart();
    },
    onLabelGroupAdded() {
      if (this.search.trim() != '') {
        this.$refs.searchBar.clearSearch();
        return;
      }
      this.getGroups();
    },
    goToDetail(label) {
      let theLabel = { id: -1, name: 'New Label', description: '' };
      if (label) {
        theLabel = label;
      }
      this.$store.commit('updateSelectedLabel', theLabel);
      this.$router.push({
        name: 'LabelDetail',
        params: { id: theLabel.id },
      });
    },
    getGroups() {
      this.isLoading = true;
      this.savePanelState();
      const params = {
        search: this.search,
        loaderText: 'Loading...',
      };
      const url = 'get-label-groups-with-count?format=json';
      return this.$axios
        .post(url, params)
        .then((response) => {
          this.isLoading = false;
          this.groups = response.data;
          this.loadPanelState();
        })
        .catch((error) => {
          this.isLoading = false;
          return error;
        });
    },
    onLabelGroupDelete(group) {
      let index = (index = this.groups.findIndex((g) => g.id == group.id));
      if (index != -1) {
        this.groups.splice(index, 1);
      }
    },
    changeGroupOrder(event) {
      this.expanded = [];
      const movedItem1 = { ...this.groups[event.index] };

      const movedItem2 = {
        ...this.groups[event.index + event.value],
      };

      this.$set(this.groups, event.index, movedItem2);
      this.$set(this.groups, event.index + event.value, movedItem1);
    },
    startOrderGroups(e) {
      // prevent group to expand
      e.stopPropagation();
      this.isOrdering = true;
      this.expanded = [];
      this.savedPanelState = [];
    },
    updateGroupPositions() {
      this.isOrdering = false;
      const groups = this.groups.map((g, index) => {
        g.isOrdering = false;
        return {
          id: g.id,
          position: index + 1,
        };
      });

      const params = {
        labelGroups: groups,
        loaderText: 'Updating positions...',
      };
      const url = 'update-label-groups-positions?format=json';
      this.$axios.post(url, params).catch((error) => error);
    },
    onSelect(event) {
      if (event.value) {
        this.selected = this.selected
          .concat(event.labels)
          // remove duplicates
          .filter(
            (item, index, self) =>
              self.findIndex((t) => t.id == item.id) == index,
          );
      } else {
        this.selected = this.selected.filter(
          (selected) => !event.labels.some((l) => l.id == selected.id),
        );
      }
    },
    handleUnselect(event) {
      this.selected = this.selected.filter(
        (selected) => selected.id != event.id,
      );
    },
    refreshGroups() {
      this.selected = [];
      this.getGroups().then(() => {
        this.refreshOpenPanels();
      });
    },
    refreshOpenPanels() {
      this.labelsTableKey++;
    },
  },
  mounted() {
    this.getGroups();
  },
};
</script>
