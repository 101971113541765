<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            :text="!isGroupExpanded"
            small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text"> Are you sure? </v-card-title>
      <v-card-text class="mt-2 text-body-1" v-if="labelGroup.count > 0">
        Deleting this group will also delete its
        {{ labelGroup.count }} label(s).
      </v-card-text>
      <v-card-text class="mt-2 text-body-1" v-if="labelGroup.count == 0">
        This action cannot be undone.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="dialog = false"
          text
          class="msaBlue--text font-weight-bold"
        >
          Cancel
        </v-btn>

        <v-btn @click="confirm()" class="msaBlue white--text"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    labelGroup: {
      type: Object,
    },
    isGroupExpanded: {
      type: Boolean,
    },
  },
  data() {
    return { dialog: false };
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'Deleting...',
        labelGroupId: this.labelGroup.id,
      };

      const url = 'delete-label-group?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.dialog = false;
          this.$emit('labelGroupDelete');
        })
        .catch((error) => error);
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
