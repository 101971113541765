<template>
  <span>
    <v-data-table
      v-model="thisSelected"
      :headers="headers"
      :footer-props="{
        'items-per-page-options': [20, 30, 50],
      }"
      :items="labels"
      :server-items-length="labelCount"
      :loading="loading"
      show-select
      :item-class="rowClass"
      checkbox-color="msaBlue"
      class="labels-table"
      :options.sync="options"
      @item-selected="onSelect($event)"
      @toggle-select-all="onSelect($event)"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="goToDetail(item)"
              text
              small
              color="msaBlue"
              v-on="on"
              :data-testid="`edit-label-${item.name}`"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar v-model="showPublishSnackbar" :timeout="2000">
      {{ snackBarText }}
    </v-snackbar>
  </span>
</template>

<script>
import { PAGINATION } from '@/constants';
export default {
  props: {
    search: {
      type: String,
      default: '',
    },
    groupId: {
      type: Number,
    },
    selected: {
      type: Array,
    },
    labelCount: {},
    type: Number,
  },
  data() {
    return {
      snackBarText: '',
      showPublishSnackbar: false,
      labels: [],
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
        },
        {
          text: 'Companies',
          value: 'companiesCount',
          class: 'lightGrey',
          align: 'center',
          width: '120px',
        },
        {
          text: 'Documents',
          value: 'documentsCount',
          class: 'lightGrey',
          align: 'center',
          width: '120px',
        },
        {
          text: 'Courses',
          value: 'coursesCount',
          class: 'lightGrey',
          align: 'center',
          width: '100px',
        },
        {
          text: 'Form Templates',
          value: 'formTemplatesCount',
          class: 'lightGrey',
          align: 'center',
          width: '150px',
        },
        {
          text: 'Actions',
          value: 'actions',
          class: 'lightGrey',
          align: 'center',
          sortable: false,
          width: '80px',
        },
      ],
      loading: false,
      thisSelected: [],
      page: PAGINATION.DEFAULT_PAGE,
      perPage: 20,
      options: {
        page: PAGINATION.DEFAULT_PAGE,
        itemsPerPage: PAGINATION.DEFAULT_PERPAGE,
        sortBy: ['name'],
        sortDesc: [false],
      },
    };
  },
  watch: {
    groupId() {
      this.getLabels();
    },
    options: {
      handler() {
        this.getLabels();
      },
      deep: true,
    },
    search: function () {
      this.options.page = 1;
      this.getLabels();
    },
    selected() {
      this.checkSelected();
    },
  },
  methods: {
    goToDetail(label) {
      this.$store.commit('updateSelectedLabel', label);
      this.$router.push({
        name: 'LabelDetail',
        params: { id: label.id },
      });
    },
    getLabels() {
      this.loading = true;
      const params = {
        search: this.search,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy[0] ?? 'name',
        sortDesc: this.options.sortDesc[0] ?? false,
        labelGroupId: this.groupId,
        loaderText: 'Loading...',
      };

      const url = 'get-labels-by-group?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.labels = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          return error;
        });
    },
    checkSelected() {
      this.thisSelected = this.labels.filter((label) =>
        this.selected.some((selected) => selected.id == label.id),
      );
    },
    onSelect(event) {
      if (event.item != undefined) {
        this.onSelectOne(event);
      }

      // Handle event when user uses SHIFT to select multiple items
      if (event.currentItem != undefined) {
        event.item = event.currentItem;
        this.onSelectOne(event);
      }

      if (event.items != undefined) {
        this.onSelectAll(event);
      }
    },
    onSelectOne(event) {
      this.$emit('onSelect', {
        labels: [event.item],
        value: event.value,
      });
    },
    onSelectAll(event) {
      if (event.value) {
        this.thisSelected = event.items;
      } else {
        this.thisSelected = [];
      }
      this.$emit('onSelect', {
        labels: event.items,
        value: event.value,
      });
    },
    rowClass() {
      return 'msaBlue--text';
    },
    handleDeletionConfirmed(trainingRecordId) {
      const index = this.thisSelected.findIndex(
        (t) => t.id == trainingRecordId,
      );
      if (index != -1) {
        this.onSelectOne({ item: this.thisSelected[index], value: false });
      }
      this.getLabels();
    },
  },
  mounted() {},
};
</script>

<style>
.labels-table .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
