<template>
  <v-dialog v-model="dialog" width="1000px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="green"
        overlap
        :content="selectedItems.length"
        :value="selectedItems.length"
        :style="`position: fixed; bottom: 20px; z-index: 80; left: ${floatBtnLeftPosition}px
        }`"
      >
        <v-btn
          elevation="5"
          class="amber lighten-1 msaBlue--text"
          large
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="pr-2"> mdi-file-document-multiple-outline </v-icon>
          Selected Labels
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col> Edit Labels </v-col>
          <v-col class="shrink">
            <v-btn icon @click="close()">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-row align="center">
          <v-col class="shrink pr-0">
            <v-icon>mdi-folder-outline</v-icon>
          </v-col>
          <v-col class="grow">
            <CustomizedAutoComplete
              :value="globalGroupId"
              hide-details
              :items="groups"
              label="Select a group to move labels to"
              @change="
                globalGroupId = $event;
                applyGlobalGroup();
              "
            />
          </v-col>
          <v-col cols="12" v-if="!isMobile">
            <v-card>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="lightBg">
                    <tr>
                      <th class="text-left">Selected Labels</th>
                      <th>Group</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in selectedItems" :key="item.id">
                      <td>
                        {{ item.name }}
                      </td>
                      <td :style="{ width: '350px' }">
                        <CustomizedAutoComplete
                          :value="item.labelGroupId"
                          hide-details
                          :items="groups"
                          @change="
                            globalGroupId = null;
                            item.labelGroupId = $event;
                          "
                          label="Select group"
                        />
                      </td>
                      <td :style="{ width: '60px' }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              icon
                              color="red"
                              @click="unselect(item)"
                              v-on="on"
                            >
                              <v-icon> mdi-minus-circle-outline </v-icon>
                            </v-btn>
                          </template>
                          <span>Remove From List</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col v-if="isMobile" cols="12">
            <v-card v-for="item in selectedItems" :key="item.id" class="mb-4">
              <v-card-title class="text-body-1 msaGrey">
                {{ item.name }}
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row no-gutters>
                  <v-col class="shrink" cols="9">
                    <CustomizedAutoComplete
                      :value="item.labelGroupId"
                      hide-details
                      :items="groups"
                      @change="
                        globalGroupId = null;
                        item.labelGroupId = $event;
                      "
                      label="Select group"
                    />
                  </v-col>
                  <v-col
                    class="shrink ml-4"
                    cols="2"
                    style="border: thin red solid"
                  >
                    <v-btn icon color="red" @click="unselect(item)">
                      <v-icon> mdi-minus-circle-outline </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="red white--text" @click="deleteAll()">
          Delete All Labels
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="msaBlue white--text" @click="confirm()"> confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    selectedItems: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      loaded: false,
      globalGroupId: null,
      groups: [],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    floatBtnLeftPosition() {
      return window.innerWidth / 2 - 292 / 2;
    },
  },
  methods: {
    confirm() {
      const params = {
        loaderText: 'Saving...',
        labels: this.selectedItems.map((item) => {
          return { id: item.id, labelGroupId: item.labelGroupId };
        }),
      };

      const url = 'move-labels?format=json';

      this.$axios
        .post(url, params)
        .then(() => {
          this.$emit('moveSelected');
          this.close();
        })
        .catch((error) => error);
    },
    unselect(item) {
      this.$emit('onUnselect', item);
      if (this.selectedItems.length == 0) {
        this.close();
      }
    },
    close() {
      this.dialog = false;
    },
    deleteAll() {
      this.$root.showMessage(
        'Confirm',
        'Are you sure? This is irreversible.',
        () => confirm(),
        () => false,
        'Confirm',
        'Cancel',
      );
      const confirm = () => {
        const itemIds = this.selectedItems.map((i) => i.id);
        const params = {
          loaderText: 'Deleting labels...',
          labelIds: itemIds,
        };
        const url = 'delete-labels?format=json';
        this.$axios
          .post(url, params)
          .then(() => {
            this.$emit('deleteSelected');
            this.close();
          })
          .catch((error) => error);
      };
    },
    applyGlobalGroup() {
      this.selectedItems.forEach((i) => (i.labelGroupId = this.globalGroupId));
    },
    getLabelGroups() {
      this.$axios.post('get-label-groups?format=json', {}).then((response) => {
        this.groups = response.data;
      });
    },
  },
  mounted() {
    this.getLabelGroups();
  },
};
</script>

<style>
/* override publish button disabled style */
#publish-btn-toggle .v-btn--disabled.publish-btn {
  background-color: #3564ac !important;
  color: white !important;
}

#publish-btn-toggle .v-btn--disabled.publish-btn .publish-icon {
  color: #4caf50 !important;
}
</style>
<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
