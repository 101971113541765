<template>
  <span>
    <v-btn
      :disabled="currentIndex == 0"
      icon
      small
      @click="
        $emit('onOrder', { index: currentIndex, value: -1 });
        $event.stopPropagation();
      "
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-btn
      :disabled="currentIndex == maxIndex"
      icon
      small
      @click="
        $emit('onOrder', { index: currentIndex, value: 1 });
        $event.stopPropagation();
      "
    >
      <v-icon>mdi-arrow-down</v-icon>
    </v-btn>
    <v-btn
      icon
      @click="
        $emit('updatePositions');
        $event.stopPropagation();
      "
      small
    >
      <v-icon color="green">mdi-check</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: 'ChangeOrderButtonGroup',
  props: {
    currentIndex: {
      type: Number,
    },
    maxIndex: {
      type: Number,
    },
  },
};
</script>

<style></style>
